import PropTypes from 'prop-types';
import React, { forwardRef, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import {
  PopoverWrapper,
  StyledPopoverBase,
  TriggerWrapper,
} from '../styles/popover';

/**
 * Popover Component
 *
 * The Popover component provides a customizable popover functionality that can
 * be triggered by an element. Design to be used with usePopover hook. It relies on
 * the react-popper library (https://popper.js.org/react-popper/) for positioning the popover.
 *
 * Props:
 *   children: The content to be displayed within the popover.
 *   disabled: indicate wether or not the popover should apear on trigger hover
 *   display: determines the css display attribute of the trigger wrapper
 *   hideArrow: Determines whether the arrow should be hidden.
 *   placement: The placement of the popover relative to the trigger.
 *   shown: Indicates whether the popover should be shown or hidden.
 *   toggle: Function to toggle the visibility of the popover.
 *   trigger: The element that triggers the popover.
 *   triggerId: html id attribute for the trigger wrapper
 *   width: The width of the popover.
 */

export const Popover = forwardRef(
  (
    {
      children,
      disabled,
      display,
      hideArrow,
      placement,
      shown,
      toggle,
      trigger,
      triggerId,
      width,
      style,
    },
    ref
  ) => {
    const portalContainerRef = useRef(getPortalContainer('popover-wrapper'));
    const [referenceElement, setReferenceElement] = useState();
    const [popperElement, setPopperElement] = useState();
    const [arrowElement, setArrowElement] = useState();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
      placement,
      modifiers: [
        { name: 'arrow', options: { element: arrowElement, padding: 8 } },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            padding: 8, // padding from the end of the screen
          },
        },
      ],
      strategy: 'fixed',
    });

    return (
      <PopoverWrapper display={display} ref={ref}>
        <TriggerWrapper
          disabled={disabled}
          id={triggerId}
          onClick={toggle}
          type="button"
          ref={setReferenceElement}
          display={display}
          role="popover-trigger" //eslint-disable-line
        >
          {trigger}
        </TriggerWrapper>

        {shown &&
          createPortal(
            <StyledPopoverBase
              // prevent closing the popover when clicked inside
              onClick={(e) => e.stopPropagation()}
              ref={setPopperElement}
              style={{ ...styles.popper, ...style }}
              width={width}
              {...attributes.popper}
              role="popover" // eslint-disable-line
            >
              {renderPopoverContent(children)}
              {hideArrow ? null : (
                <div
                  id="popover_arrow"
                  ref={setArrowElement}
                  style={styles.arrow}
                />
              )}
            </StyledPopoverBase>,
            portalContainerRef.current
          )}
      </PopoverWrapper>
    );
  }
);

const renderPopoverContent = (children) => {
  return typeof children === 'string' ? (
    <div
      className="popover-content"
      dangerouslySetInnerHTML={{ __html: children }}
    />
  ) : (
    <div className="popover-content">{children}</div>
  );
};

export const PopoverProps = {
  children: PropTypes.node,
  hideArrow: PropTypes.bool,
  trigger: PropTypes.node.isRequired,
  width: PropTypes.string,
  display: PropTypes.oneOf(['block', 'inline-block', 'flex']),
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

Popover.displayName = 'Popover';

Popover.propTypes = {
  ...PopoverProps,
  toggle: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
};

// returns the portal wrapper element, creating it along the way if it does not exist yet
const getPortalContainer = (containerId) => {
  const portalContainer =
    document.getElementById(containerId) || document.createElement('div');
  // this is how we check if the container was already created
  if (portalContainer.id === containerId) {
    return portalContainer;
    // otherwise append element to body
  } else {
    portalContainer.id = containerId;
    document.body.appendChild(portalContainer);
    return portalContainer;
  }
};
